<template>
  <div>
    <EditButton
      :disabled="!$atividades.permissoes.includes(14)"
      @func="showModalEditarEmail = true"
    ></EditButton>
    <SideBar
      title="Editar email"
      @hide="cancelar()"
      :active="showModalEditarEmail"
      size="small"
      textSucces="Atualizar"
      icon="edit"
      :disabled="true"
      :item="email"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              label-placeholder="Assunto"
              v-validate="'required'"
              name="assunto"
              v-model="email.assunto"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <v-textarea
              class="inputx w-full"
              label-placeholder="Corpo"
              v-validate="'required'"
              name="corpo"
              v-model="email.corpo"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: { email: Object },
  data() {
    return {
      showModalEditarEmail: false,
      originalData: {},
      error: false,
    };
  },
  mounted() {
    this._beforeEditingCache = Object.assign({}, this.email);
    this.originalData = this.email;
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.editarEmail();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarEmail() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(
          `tipo_email/${this.email.id}`,
          this.email
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição de tipo de email N°" + res.id,
        };
        await this.$logger(logData);

        this.$vs.notify(this.$notify.Success);
        this.showModalEditarEmail = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    cancelar() {
      Object.assign(this.email, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.showModalEditarEmail = false;
    },
  },
  components: components,
};
</script>

<style></style>
