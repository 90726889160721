<template>
  <div class="w-full">
    <vs-table
      maxHeight="68vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="emails"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Emails</h4>
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome" style="width: 15%">Nome</vs-th>
        <vs-th sort-key="assunto" style="width: 15%">Assunto</vs-th>
        <vs-th sort-key="corpo" style="width: 60%">Corpo</vs-th>
        <vs-th style="width: 10%">Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>
          <vs-td :data="data[indextr].assunto">
            {{ data[indextr].assunto }}
          </vs-td>
          <vs-td :data="data[indextr].corpo">
            {{ data[indextr].corpo }}
          </vs-td>
          <vs-td class="actions">
            <ModalEditarEmail @update="getEmails" :email="data[indextr]" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="emails.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum email cadastrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalEditarEmail from "./editar.vue";
export default {
  data() {
    return {
      emails: [],
    };
  },
  methods: {
    async getEmails() {
      this.$vs.loading();
      try {
        this.emails = await this.$http.get("tipo_email");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
  },
  components: {
    ModalEditarEmail,
  },
  mounted() {
    this.getEmails();
  },
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
